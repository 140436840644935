<template>
  <div class="footer-nav">
    <div class="logo">
      <img src="@/assets/footer.png" />
    </div>
    <div class="list">
      <div class="item">
        <div><img src="@/assets/phone.png" />电话：0351-6329000</div>
        <!-- <div><img src="@/assets/email.png" />邮箱：799395595@qq.com</div> -->
        <div>
          <img
            src="@/assets/local.png"
          />地址：山西省太原市万柏林区滨河西路南段85号柏林国际商务中心15-20层（入驻北控晋马）-2007室
        </div>
      </div>
      <div class="item">
        <div class="ercode">
          <img src="@/assets/ercode1.png" />
          <p>订阅号</p>
        </div>
        <div class="ercode">
          <img src="@/assets/ercode2.png" />
          <p>服务号</p>
        </div>
      </div>
    </div>
    <!-- <p class="guan">
      每橙官网：Copyright © 2023 山西每橙科技集团股份有限公司All Rights Reserved.|每橙备案号：晋ICP备2023020369号-1
    </p> -->
    <p class="guan">
      <img style="width: 20px; height: 20px" src="@/assets/footer_img.jpg" />
      <a
        class="guan_link"
        href="https://beian.mps.gov.cn/#/query/webSearch?code=14010902001562"
        rel="noreferrer"
        target="_blank"
      >
        晋公网安备14010902001562号
      </a>
      | Copyright © 2023 山西云科智通科技有限公司All Rights Reserved. |
      <a class="guan_link" href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank"> 晋ICP备2023020369号-1 </a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.footer-nav {
  margin-top: 100px;
  // padding-top: 40px;
  background: #212832;
  height: 440px;
  color: #fff;
  .logo {
    text-align: center;
    border-bottom: 1px solid #615959;
    img {
      margin: 40px 0;
      width: 200px;
      height: 100%;
    }
  }
  .list {
    width: 1200px;
    margin: auto;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      div {
        margin-top: 20px;
      }
      img {
        margin-right: 10px;
        width: 36px;
        height: 36px;
        vertical-align: middle;
      }
      .ercode {
        margin-right: 20px;
        display: inline-block;
        text-align: center;
        img {
          width: 100px;
          height: 100px;
          margin-right: 0;
        }
      }
    }
  }
  .guan {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    color: #666;
    text-align: center;
    font-size: 14px;
    font-family: "PingFang SC";
    .guan_link {
      cursor: pointer;
      color: #666;
      margin: 0 6px;
      font-size: 14px;
      font-family: "PingFang SC";
      text-decoration: none;
    }
  }
}
</style>
