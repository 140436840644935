import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
  },
  {
    path: "/business",
    name: "business",
    component: () => import("../views/business.vue"),
  },
  {
    path: "/businessCar",
    name: "businessCar",
    component: () => import("../views/businessCar.vue"),
  },
  {
    path: "/businessXiyan",
    name: "businessXiyan",
    component: () => import("../views/businessXiyan.vue"),
  },
  {
    path: "/ecology",
    name: "ecology",
    component: () => import("../views/ecology.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
  },
];

const router = new VueRouter({
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
