<template>
  <div id="app">
    <HeaderNav></HeaderNav>
    <router-view />
    <FooterNav></FooterNav>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import FooterNav from "@/components/FooterNav.vue";
export default {
  components: { HeaderNav, FooterNav },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
}
// li {
//   list-style: none;
// }
p {
  text-wrap: wrap;
}
html,
body,
#app {
  min-width: 1200px;
  overflow: auto;
}
</style>
