<template>
  <div class="header">
    <div class="box">
      <img src="@/assets/logo2.png" />
      <div class="nav-list">
        <div :class="active === '/' && 'active'" @click="handle('/')">首页</div>
        <div
          @click="handle(businessPath)"
          @mouseenter="showList = true"
          @mouseleave="showList = false"
          :class="active.includes('/business') && 'active'">
          {{ businessTxt }}业务
          <section v-if="showList">
            <span :class="businessPath === '/business' && 'act'" @click.stop="handle('/business')">生活</span>
            <span :class="businessPath === '/businessCar' && 'act'" @click.stop="handle('/businessCar')">专车</span>
            <span :class="businessPath === '/businessXiyan' && 'act'" @click.stop="handle('/businessXiyan')">喜宴</span>
          </section>
        </div>
        <div :class="active === '/ecology' && 'active'" @click="handle('/ecology')">每橙生态</div>
        <div :class="active === '/about' && 'active'" @click="handle('/about')">关于我们</div>
        <div :class="active === '/contact' && 'active'" @click="handle('/contact')">联系我们</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "/",
      showList: false,
      businessTxt: "生活",
      businessPath: "/business",
      navList: [
        { name: "首页", path: "/" },
        { name: "每橙业务", path: "/business" },
        { name: "每橙生态", path: "/ecology" },
        { name: "关于我们", path: "/about" },
        { name: "联系我们", path: "/contact" },
      ],
    };
  },
  // watch: {
  //   $route: {
  //     handler: function (to, from) {
  //       // if (to.path.includes("business")) this.active = "/business";
  //       // else this.active = to.path;
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },
  methods: {
    handle(path) {
      if (path.includes("/business")) {
        if (path === "/business") this.businessTxt = "生活";
        else if (path === "/businessCar") this.businessTxt = "专车";
        else if (path === "/businessXiyan") this.businessTxt = "喜宴";
        this.businessPath = path;
      }
      this.active = path;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 10px 1px rgba(255, 80, 27, 0.2);
  img {
    width: 180px;
    height: 60px;
  }
  .box {
    width: 1200px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-list {
    display: flex;
    justify-content: space-between;
    width: 500px;
    font-size: 18px;
    color: #666;
    div {
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      cursor: pointer;
      position: relative;

      section {
        // width: 100%;
        width: 180px;
        // height: 50px;
        position: absolute;
        left: -50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        transition: all 0.5s linear;
        border: 2px solid #eee;
        overflow: hidden;
        border-radius: 10px;
        z-index: 1;
        span {
          border-bottom: 2px solid #eee;
          padding-left: 20px;
          width: 100%;
          background: #fff;
          color: #666;
          cursor: pointer;
        }
        .act {
          color: #fff;
          background: #fd987a;
        }
      }
    }
    .active {
      color: #ff501b;
      border-bottom: 2px solid #ff501b;
    }
  }
}
</style>
